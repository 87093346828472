@import '../../../../../../styles/guide';

.accordionTitle {
  align-items: center;
  border-top: 1px solid $GrayColor;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.accordionTitleHeader {
  @extend %h5;
  color: $SecondaryColor;
  font-weight: bold;
}

.accordionArrow {
  cursor: pointer;
  font-size: 1.25rem !important;
}

.accordionContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sliderContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
