@import '../../../../../styles/guide';

.container {
  background-color: $WhiteColor;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px;
  min-width: 350px;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.sliderContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mapControlLabel {
  @extend %h4;
  color: $SecondaryColor;
}
